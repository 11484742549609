const DeviceWidth = ((): DeviceWidth => {
  const deviceWidth: DeviceWidth = {
    pc: false,
    mobile: false,
    tablet: false,
    sp: false,
  };

  let screenWidth: number = window.innerWidth;

  const setWidth = (): void => {
    if (screenWidth > 991) {
      for (const key of Object.keys(deviceWidth)) {
        if (key === 'pc') {
          deviceWidth[key] = true;
        } else {
          deviceWidth[key] = false;
        }
      }
    } else if (screenWidth > 767 && screenWidth <= 991) {
      for (const key of Object.keys(deviceWidth)) {
        if (key === 'tablet' || key === 'mobile') {
          deviceWidth[key] = true;
        } else {
          deviceWidth[key] = false;
        }
      }
    } else if (screenWidth <= 767) {
      for (const key of Object.keys(deviceWidth)) {
        if (key === 'sp' || key === 'mobile') {
          deviceWidth[key] = true;
        } else {
          deviceWidth[key] = false;
        }
      }
    }
  };

  setWidth();
  // on resize
  window.addEventListener('resize', () => {
    screenWidth = window.innerWidth;
    setWidth();
    return deviceWidth;
  });

  return deviceWidth;
})();

export default DeviceWidth;
