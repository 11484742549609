import cookieValue from 'utils/cookieValue';

export default class AddTestPatternToLink {
  private optimizeCookieValue: string | undefined;

  private baseLink: string;

  private targetLinks: NodeListOf<Element>;

  constructor() {
    this.optimizeCookieValue = cookieValue('_gaexp');
    this.baseLink = '/assessment';
    this.targetLinks = document.querySelectorAll(`a[href^='${this.baseLink}']`);
  }

  onLoad(): void {
    if (!this.optimizeCookieValue) return;
    const abTestId = cookieValue('_gaexp').split('.')[2];
    const abTestPattern = cookieValue('_gaexp').split('.').slice(-1)[0];
    for (let i = 0; i < this.targetLinks.length; i++) {
      const link = this.targetLinks[i] as HTMLAnchorElement;
      const url = new URL(link.href);
      if (url) {
        url.searchParams.set(`ab_${abTestId}`, `${abTestPattern}`);
        link.setAttribute('href', url.toString());
      }
    }
  }
}
