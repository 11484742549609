import EmailValidation from '../validation/emailValidation';
import DeviceWidth from '../../utils/device';
import { showDomains, hideDomains } from './domainSuggestUtility';

export default class DomainSuggest {
  private emailFormOuterBox: HTMLCollectionOf<Element>;

  protected emailForms: HTMLCollectionOf<Element>;

  protected domainLists: HTMLCollectionOf<Element>;

  protected emailValidation: EmailValidation;

  constructor() {
    this.emailFormOuterBox = document.getElementsByClassName('js-email');
    this.emailForms = document.getElementsByClassName('js-emailForm');
    this.domainLists = document.getElementsByClassName('c-form__domains-box');
  }

  enableSuggestion(): void {
    if (DeviceWidth.sp) {
      this.emailValidation = new EmailValidation();
      for (let i = 0; i < this.emailFormOuterBox.length; i++) {
        const emailForm = this.emailForms[i] as HTMLInputElement;
        const domainList = this.domainLists[i];

        ['focus', 'click'].forEach((eventName: string) => {
          emailForm.addEventListener(eventName, event => {
            event.stopPropagation(); // prevent event bubbling
            showDomains(domainList, emailForm);
          });
        });

        // iOSキーボードによるフォームフォーカス先移動時に隠す
        emailForm.addEventListener('blur', e => {
          e.stopPropagation();
          window.setTimeout(() => {
            this.emailValidation.check(i);
            hideDomains(domainList, emailForm);
          }, 100);
        });

        // close domains table when finished autofill.
        const domains = domainList.getElementsByClassName('c-form__domains-domain');
        for (let j = 0; j < domains.length; j++) {
          const that = domains[j] as HTMLInputElement;
          that.addEventListener('click', event => {
            event.preventDefault();
            emailForm.value += that.innerText;
            this.emailValidation.check(i);
            hideDomains(domainList, emailForm);
            // submit ボタンの enable/disable を制御するために、change イベントを発火する
            emailForm.form.dispatchEvent(new Event('change'));
          });
        }
      }
      document.addEventListener('click', e => {
        const len = this.emailFormOuterBox.length;
        for (let i = 0; i < len; i++) {
          if (!this.emailFormOuterBox[i].contains(e.target as Element)) {
            hideDomains(this.domainLists[i], this.emailForms[i] as HTMLInputElement);
          }
        }
      });

      Array.from(this.emailForms).forEach((form: Element, i: number) => {
        form.addEventListener('change', () => {
          this.emailValidation.check(i);
        });
      });
    } else {
      new EmailValidation().validate();
    }
  }
}
