export type StickyPosition = 'top' | 'bottom';

type StickyOptions = {
  target: HTMLElement;
  container: HTMLElement;
  startPosition: number;
  sameRoleElementWithTarget?: HTMLElement;
  stickyPosition?: StickyPosition;
  isPermanent?: boolean;
};

export default class Sticky {
  private target: HTMLElement;

  private container: HTMLElement;

  private startPosition: number;

  private sameRoleElementWithTarget?: HTMLElement;

  private stickyPosition?: StickyPosition;

  private isPermanent: boolean;

  constructor(options: StickyOptions) {
    this.target = options.target;
    this.container = options.container;
    this.startPosition = options.startPosition;
    this.sameRoleElementWithTarget = options.sameRoleElementWithTarget;
    this.stickyPosition = options.stickyPosition;
    this.isPermanent = options.isPermanent || false;
  }

  onScroll(): void {
    window.addEventListener('scroll', () => {
      const scrollY = window.pageYOffset;
      const isSticky = this.target.classList.contains('-sticky');
      const displayTarget: boolean = this.positionInRangeDisplayingTarget(scrollY);

      if (displayTarget && !isSticky) {
        this.target.classList.add('-sticky');
        this.adjustContainerHeight();
      } else if (!displayTarget && isSticky && !this.isPermanent) {
        this.target.classList.remove('-sticky');
        this.resetContainerHeight();
      }
    });
  }

  private adjustContainerHeight(): void {
    const targetHeight = this.target.clientHeight;
    if (this.stickyPosition === 'top') {
      this.container.style.paddingTop = `${targetHeight}px`;
    } else if (this.stickyPosition === 'bottom') {
      this.container.style.paddingBottom = `${targetHeight}px`;
    }
  }

  private resetContainerHeight(): void {
    this.container.style.paddingTop = `0px`;
  }

  private positionInRangeDisplayingTarget(scrollY: number): boolean {
    if (this.sameRoleElementWithTarget === undefined) {
      return scrollY > this.startPosition;
    }

    const endPosition = this.sameRoleElementWithTarget.getBoundingClientRect().bottom - window.innerHeight; // sameRoleElementWithTarget の下部が表示領域に入ったら、0 になる。
    return scrollY > this.startPosition && endPosition > 0;
  }
}
