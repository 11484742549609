export default class DisabledHandler {
  private form: HTMLCollectionOf<HTMLFormElement>;

  private error: HTMLCollectionOf<HTMLInputElement>;

  constructor() {
    this.form = document.getElementsByClassName('js-form-validation') as HTMLCollectionOf<HTMLFormElement>;
    this.error = document.getElementsByClassName('is-error') as HTMLCollectionOf<HTMLInputElement>;
  }

  validate(requireCheckbox = false): void {
    for (let i = 0; i < this.form.length; i++) {
      const form = this.form[i];
      form.addEventListener('change', () => {
        Array.from(form.getElementsByClassName('js-submit-validation')).forEach((submitBtn: HTMLInputElement) => {
          const hasError = this.error.length !== 0;
          // https://github.com/airbnb/javascript/issues/641#issuecomment-250997824
          if (requireCheckbox) {
            submitBtn.disabled = hasError || this.hasNoCheck(i); // eslint-disable-line no-param-reassign
          } else {
            submitBtn.disabled = hasError; // eslint-disable-line no-param-reassign
          }
        });
      });
    }
  }

  /**
   * 少なくともひとつはチェックボックスにチェックされている必要がある場合
   */
  private hasNoCheck(index: number): boolean {
    const form = this.form[index];
    // checkboxを取得し、配列化
    const checkboxes: HTMLInputElement[] = [].slice.call(form.querySelectorAll('input[type=checkbox]'));
    // ひとつでもチェックされているか判定
    const hasNoCheck = !checkboxes.some(checkbox => checkbox.checked);
    return hasNoCheck;
  }
}
