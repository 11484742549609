import Axios from 'axios';
import '../config/axiosConfig';
import Validate from '../validation/validate';

interface Params {
  postalCode: string;
  path: string;
  targetPos: HTMLInputElement;
  errorPos: HTMLElement;
}

export default (param: Params): void => {
  const { postalCode, path, targetPos, errorPos } = param;
  Axios.post(path, { postalCode }).then(res => {
    if (res.data.msg) {
      Validate.failed(targetPos, errorPos, res.data.msg);
    } else {
      Validate.resolved(targetPos, errorPos);
    }
  });
};
