import Axios from 'axios';

Axios.interceptors.request.use(
  config => {
    const axiosConfig = config;
    if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
      axiosConfig.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
    return axiosConfig;
  },
  error => {
    return Promise.reject(error);
  },
);
