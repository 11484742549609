import Validate from './validate';

export default class EmailValidation extends Validate {
  private reg: RegExp;

  private errorMessage: string;

  constructor() {
    super();
    this.targets = document.getElementsByClassName('js-email-validation') as HTMLCollectionOf<HTMLInputElement>;
    this.errorPositions = document.getElementsByClassName('js-email-error') as HTMLCollectionOf<HTMLInputElement>;
    this.errorMessage = 'メールアドレスの形式が違います';
    this.reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/);
  }

  check(index: number): void {
    const target = this.targets[index];
    const { value } = target;
    if (!this.reg.test(value) && value) {
      Validate.failed(target, this.errorPositions[index], this.errorMessage);
    } else if (value) {
      Validate.resolved(target, this.errorPositions[index]);
    }
  }

  checkAll(): void {
    const len = this.targets.length;
    for (let i = 0; i < len; i++) {
      this.check(i);
    }
  }
}
