import Axios from 'axios';
import Validate from './validate';
import postalCodeValidationApi from '../postalCodeApi/apiValidation';
import '../config/axiosConfig';

export default class PostalCodeValidation extends Validate {
  private reg: RegExp;

  private errorMessage: {
    digit: string;
    area: string;
  };

  private reportInvalidPostalCode: boolean;

  constructor(
    private type: string = 'all',
    reportInvalidPostalCode = false,
  ) {
    super();
    this.targets = document.getElementsByClassName('js-postal-code-validation') as HTMLCollectionOf<HTMLInputElement>;
    this.errorPositions = document.getElementsByClassName('js-postal-code-error') as HTMLCollectionOf<HTMLInputElement>;
    this.errorMessage = {
      digit: '7桁の半角数字で入力してください',
      area: '現在このエリアは対象外となっております',
    };
    this.reg = new RegExp(/^\d{3}-?\d{4}$|^[0-9]{7}$/);
    this.type = type;
    this.reportInvalidPostalCode = reportInvalidPostalCode;
  }

  check(index: number): void {
    const target = this.targets[index];
    const { value } = target;
    if (!this.reg.test(value) && value) {
      Validate.failed(target, this.errorPositions[index], this.errorMessage.digit);
    } else if (value) {
      Validate.resolved(target, this.errorPositions[index]);
      if (this.type === 'kaitori') {
        PostalCodeValidation.addHyphen(value, target);
        PostalCodeValidation.postValue(value, this.type).then(result => {
          if (result.assessable_area === true) {
            Validate.resolved(target, this.errorPositions[index]);
          } else {
            Validate.failed(target, this.errorPositions[index], this.errorMessage.area);
          }
          target.form.dispatchEvent(new Event('change'));
        });
      } else {
        const param = {
          postalCode: value,
          path: '/postal_code_validation.json',
          targetPos: target,
          errorPos: this.errorPositions[index],
        };
        postalCodeValidationApi(param);
      }
    }
  }

  focus(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].addEventListener('focus', () => {
        const target = this.targets[i];
        const { value } = target;
        target.value = value.replace(/-/g, '');
      });
    }
  }

  static addHyphen(value: string, target: HTMLInputElement): void {
    const hyphenTarget = target;
    hyphenTarget.value = value.replace(/^(\d{3})-?(\d{4})$/g, '$1-$2');
  }

  static async postValue(postalCode: string, type: string): Promise<any> {
    const res = await Axios.post('/invalid_postal_code', {
      invalid_postal_code: {
        exclusive_area: (document.getElementById('apartment_square_meter') as HTMLInputElement).value,
        apartment_name: (document.getElementById('mansion_name') as HTMLInputElement).value,
        postal_code: postalCode,
        type,
      },
    });

    return res.data;
  }
}
