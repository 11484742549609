import Cleave from 'cleave.js';

export default class FormatMask {
  postalCodeCleave: Cleave | undefined;

  constructor() {
    this.postalCodeCleave = undefined;
  }

  maskPostalCode(): void {
    const postalCodeBox = document.getElementsByClassName('js-format-postal-code');
    if (postalCodeBox.length > 0) {
      this.postalCodeCleave = new Cleave('.js-format-postal-code', {
        delimiter: '-',
        blocks: [3, 4],
        numericOnly: true,
      });
    }
  }
}
