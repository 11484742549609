export default class Accordion {
  private triggers: NodeListOf<HTMLElement>;

  private panels: NodeListOf<HTMLElement>;

  private allowToggle: boolean;

  constructor(container: HTMLElement, allowToggle = false) {
    this.triggers = container.querySelectorAll<HTMLElement>('[aria-expanded]');
    this.panels = container.querySelectorAll<HTMLElement>('[aria-hidden]');
    this.allowToggle = allowToggle;
  }

  onClick(): void {
    for (const trigger of this.triggers) {
      trigger.addEventListener('click', () => {
        const panelId = trigger.getAttribute('aria-controls');
        const panel = document.getElementById(panelId);
        if (this.allowToggle) this.collapseAll();
        this.toggle(trigger, panel);
      });
    }
  }

  private collapseAll(): void {
    for (const target of this.triggers) {
      target.setAttribute('aria-expanded', 'false');
    }
    for (const panel of this.panels) {
      panel.setAttribute('aria-hidden', 'true');
    }
  }

  private toggle(trigger: HTMLElement, panel: HTMLElement): void {
    const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
    panel.setAttribute('aria-hidden', `${isExpanded}`);
    trigger.setAttribute('aria-expanded', `${!isExpanded}`);
  }
}
