export default class SelectColor {
  private targets: HTMLCollectionOf<HTMLSelectElement>;

  constructor() {
    this.targets = document.getElementsByClassName('js-select-color') as HTMLCollectionOf<HTMLSelectElement>;
  }

  onChange(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].addEventListener('change', () => {
        const target = this.targets[i];
        SelectColor.handleSelected(target);
      });
    }
  }

  onLoad(): void {
    for (let i = 0; i < this.targets.length; i++) {
      const target = this.targets[i];
      SelectColor.handleSelected(target, 500);
    }
  }

  static handleSelected(target: HTMLSelectElement, timeout = 0): void {
    setTimeout(() => {
      if (target.value) {
        target.classList.add('-selected');
      } else {
        target.classList.remove('-selected');
      }
    }, timeout);
  }
}
