export const showDomains = (domainList: Element, emailForm: HTMLInputElement): void => {
  if (domainList.classList.contains('-hidden')) {
    domainList.classList.remove('-hidden');
    emailForm.classList.add('-domains');
  }
};

export const hideDomains = (domainList: Element, emailForm: HTMLInputElement): void => {
  if (!domainList.classList.contains('-hidden')) {
    domainList.classList.add('-hidden');
    emailForm.classList.remove('-domains');
  }
};
