import Validate from './validate';

export default class SquareMeterValidation extends Validate {
  private minSquareMeter = 40;

  private maxSquareMeter = 130;

  private checkMinSquareMeter: boolean;

  private checkMaxSquareMeter: boolean;

  constructor(checkMinSquareMeter = true, checkMaxSquareMeter = true) {
    super();
    this.targets = document.getElementsByClassName('js-square-meter-validation') as HTMLCollectionOf<HTMLInputElement>;
    this.errorPositions = document.getElementsByClassName(
      'js-square-meter-error',
    ) as HTMLCollectionOf<HTMLInputElement>;
    this.checkMinSquareMeter = checkMinSquareMeter;
    this.checkMaxSquareMeter = checkMaxSquareMeter;
  }

  check(index: number): void {
    const target = this.targets[index];
    const { value } = target;

    const squareMeter = Number(value);

    if (this.checkMinSquareMeter && squareMeter > 0 && squareMeter < this.minSquareMeter) {
      Validate.failed(target, this.errorPositions[index], `広さが${this.minSquareMeter}㎡未満はご利用いただけません`);
    } else if (this.checkMaxSquareMeter && squareMeter >= this.maxSquareMeter) {
      Validate.failed(target, this.errorPositions[index], `広さが${this.maxSquareMeter}㎡以上はご利用いただけません`);
    } else {
      Validate.resolved(target, this.errorPositions[index]);
    }
  }
}
