export default abstract class Validate {
  protected targets: HTMLCollectionOf<HTMLInputElement>;

  protected errorPositions: HTMLCollectionOf<HTMLElement>;

  static failed(target: HTMLInputElement, position: HTMLElement, errorMessage: string): void {
    const errorPosition = position;
    errorPosition.textContent = errorMessage;
    errorPosition.classList.add('is-error');
    target.classList.add('-error');
  }

  static resolved(target: HTMLInputElement, position: HTMLElement): void {
    const errorPosition = position;
    errorPosition.textContent = '';
    errorPosition.classList.remove('is-error');
    target.classList.remove('-error');
  }

  validate(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].addEventListener('change', () => {
        this.check(i);
      });
      this.targets[i].addEventListener('blur', () => {
        this.check(i);
      });
    }
  }

  /**
   * validationのcheckの内容は各module内で記述
   * checkだけでは意味が成り立たないため抽象メソッドとして記述している。
   * 抽象メソッドを含むため、クラスも抽象クラスとしている。
   */
  abstract check(index: number): void;
}
