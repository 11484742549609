export default class SelectDefault {
  private targets: HTMLCollectionOf<HTMLSelectElement>;

  private defaultValue: string;

  constructor(targets: HTMLCollectionOf<HTMLSelectElement>, defaultValue: string) {
    this.targets = targets;
    this.defaultValue = defaultValue;
  }

  onFocus(): void {
    for (let i = 0; i < this.targets.length; i++) {
      const target = this.targets[i];
      target.addEventListener('focus', () => {
        target.value = this.defaultValue;
        target.classList.add('-selected');
      });
    }
  }
}
