/**
 * Import classes
 */
import FormatNumber from 'modules/format/number';
import SelectColor from 'modules/format/selectColor';
import RequiredValidation from 'modules/validation/requiredValidation';
import PostalCodeValidation from 'modules/validation/postalCodeValidation';
import DisabledHandler from 'modules/validation/disabledHandler';
import ScrollTo from 'modules/scroll/scrollTo';
import DomainSuggest from 'modules/email/domainSuggest';
import FormatMask from 'modules/format/formatMask';
import SquareMeterValidation from 'modules/validation/squareMeterValidation';
import SelectDefault from 'modules/form/selectDefault';
import AddTestPatternToLink from 'modules/abtest/AddTestPatternToLink';
import Carousel from 'modules/carousel/carousel';
import Sticky from 'modules/scroll/sticky';
import Accordion from 'modules/accordion';
import PhoneValidation from 'modules/validation/phoneValidation';

/**
 * Make instances
 */
const formatNumber = new FormatNumber();
const selectColor = new SelectColor();
const required = new RequiredValidation();
const postalCodeValidation = new PostalCodeValidation('kaitori', true);
const disabledHandler = new DisabledHandler();
const scrollTo = new ScrollTo();
const domainSuggest = new DomainSuggest();
const formatMask = new FormatMask();
const squareMeterValidation = new SquareMeterValidation();
const layoutTargets = document.getElementsByClassName('js-layout') as HTMLCollectionOf<HTMLSelectElement>;
const defaultValue = 'two_ldk_sldk';
const selectDefault = new SelectDefault(layoutTargets, defaultValue);
const addTestPatternToLink = new AddTestPatternToLink();
const stickyContainer = document.getElementsByClassName('js-sticky-container')[0] as HTMLElement;
const telButtonTriggerEl = document.getElementsByClassName('js-telButton-trigger')[0];
const telButtonEl = document.getElementsByClassName('js-floatingBtn')[0] as HTMLElement;
if (stickyContainer && telButtonTriggerEl && telButtonEl) {
  const telButtonPosition = telButtonTriggerEl.getBoundingClientRect().top;
  const telButtonOptions = {
    target: telButtonEl,
    container: stickyContainer,
    startPosition: telButtonPosition,
  };
  const stickyTelButton = new Sticky(telButtonOptions);
  stickyTelButton.onScroll();
}
const faqContainer = document.getElementById('js-faq-container');
const faqAccordion = new Accordion(faqContainer);
const phoneValidation = new PhoneValidation();

/**
 * Execute functions
 */
// 数字フォーマット
formatNumber.onChange();
// セレクトの文字色
selectColor.onChange();
selectColor.onLoad();
// セレクトのデフォルト値
selectDefault.onFocus();
// 必須項目バリデーション
required.validate();
// 郵便番号バリデーション
postalCodeValidation.validate();
postalCodeValidation.focus();
// 部屋の広さのバリデーション
squareMeterValidation.validate();
// submitボタンクリッカブル操作
disabledHandler.validate();
// メールアドレスのドメインの入力補助
domainSuggest.enableSuggestion();
// ページ内スクロール
scrollTo.onClick();
// 郵便番号の入力補助
formatMask.maskPostalCode();
// 電話番号バリデーション
phoneValidation.validate();
// homeでA/Bテストが行われた場合、テストIDとパターンを査定フォームリンクのパラメータに追加
addTestPatternToLink.onLoad();
// よくある質問の開閉
faqAccordion.onClick();

// お客様の声カルーセル
const swiperElements = document.getElementsByClassName('js-swiper') as HTMLCollectionOf<HTMLElement>;
const options = {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  spaceBetween: 50,
  slidesPerView: 1,
  speed: 200,
  grabCursor: true,
};
const carouselArray = [];
for (let i = 0; i < swiperElements.length; i++) {
  carouselArray.push(new Carousel(swiperElements[i], options, 'all'));
}
