export default class FormatNumber {
  protected targets: HTMLCollectionOf<HTMLInputElement>;

  protected formattedValue: string;

  constructor() {
    this.targets = document.getElementsByClassName('js-number-format') as HTMLCollectionOf<HTMLInputElement>;
    this.formattedValue = '';
  }

  // 半角数字のみにフォーマット
  onChange(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].addEventListener('change', () => {
        const target = this.targets[i];
        const { value } = target;
        this.format(value);
        target.value = this.formattedValue;
      });
    }
  }

  format(value: string): void {
    this.formattedValue = FormatNumber.toHalfWidth(value);
    this.formattedValue = FormatNumber.onlyNumber(this.formattedValue);
  }

  static onlyNumber(value: string): string {
    return value.replace(/[^0-9.]/g, '');
  }

  static toHalfWidth(value: string): string {
    const halfValue: string = value.replace(/[！-～]/g, str => {
      return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
    });

    return halfValue;
  }
}
