const isCookieExists = (name: string): boolean => {
  return document.cookie.split(';').some(item => item.trim().startsWith(`${name}=`));
};

const cookieValue = (name: string): string | undefined => {
  const { cookie } = document;

  // cookieがひとつのみ登録されている場合
  if (!cookie.includes(';')) {
    return cookie.startsWith(name) ? cookie.split('=')[1] : undefined;
  }

  // cookieが複数登録されている場合
  if (!isCookieExists(name)) return undefined;
  return cookie
    .split('; ')
    .find(row => row.startsWith(name))
    .split('=')[1];
};

export default cookieValue;
