import Swiper, { Pagination, Autoplay, SwiperOptions } from 'swiper';
import DeviceWidth from 'utils/device';

export default class Carousel {
  private sw: Swiper | undefined;

  private deviceWidth: object;

  constructor(
    private swiperElement: HTMLElement,
    private options: SwiperOptions,
    // mobile includes tablet and sp
    private targetDevice: 'all' | 'pc' | 'mobile' | 'tablet' | 'sp',
  ) {
    this.sw = undefined;
    this.swiperElement = swiperElement;
    this.options = { modules: [Pagination, Autoplay], ...options };
    this.deviceWidth = DeviceWidth;
    this.targetDevice = targetDevice;
    this.init();
  }

  init(): void {
    if (this.swiperElement) {
      this.swiperElement.classList.remove('-transparent');
      switch (this.targetDevice) {
        case 'all':
          if (this.sw === undefined) {
            this.sw = new Swiper(this.swiperElement, this.options);
          } else {
            this.stop();
            this.sw = undefined;
          }
          break;
        default:
          if (this.deviceWidth[this.targetDevice] && this.sw === undefined) {
            this.sw = new Swiper(this.swiperElement, this.options);
          } else if (!this.deviceWidth[this.targetDevice] && this.sw !== undefined) {
            this.sw.destroy(true, true);
            this.sw = undefined;
          }
          break;
      }
    }
  }

  stop(): void {
    this.sw.destroy(true, true);
  }
}
