import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js';
import Validate from './validate';

export default class PhoneValidation extends Validate {
  private errorMessage: string;

  constructor() {
    super();
    this.targets = document.getElementsByClassName('js-phone-validation') as HTMLCollectionOf<HTMLInputElement>;
    this.errorPositions = document.getElementsByClassName('js-phone-error') as HTMLCollectionOf<HTMLInputElement>;
    this.errorMessage = '正しい形式の電話番号を入力してください';
  }

  check(index: number): void {
    const target = this.targets[index];
    const { value } = target;
    if (isValidPhoneNumber(value, 'JP') || value === '') {
      target.value = new AsYouType().input(value);
      Validate.resolved(target, this.errorPositions[index]);
    } else {
      Validate.failed(target, this.errorPositions[index], this.errorMessage);
    }
  }
}
