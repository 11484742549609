import Validate from './validate';

export default class RequiredValidation extends Validate {
  private errorMessage: string;

  constructor() {
    super();
    this.targets = document.getElementsByClassName('js-required-validation') as HTMLCollectionOf<HTMLInputElement>;
    this.errorPositions = document.getElementsByClassName('js-required-error') as HTMLCollectionOf<HTMLInputElement>;
    this.errorMessage = '';
  }

  check(index: number): void {
    const target = this.targets[index];
    const { value } = target;
    if (target.type === 'select-one') {
      this.errorMessage = target.dataset.type ? `${target.dataset.type}を選択してください` : '値を選択してください';
    } else {
      this.errorMessage = target.dataset.type ? `${target.dataset.type}を入力してください` : '値を入力してください';
    }
    if (value === '') {
      Validate.failed(target, this.errorPositions[index], this.errorMessage);
    } else {
      Validate.resolved(target, this.errorPositions[index]);
    }
  }
}
