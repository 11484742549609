import smoothscroll from 'smoothscroll-polyfill';

export default class ScrollTo {
  private triggers: HTMLCollectionOf<HTMLInputElement>;

  private arrivalPosition: number;

  constructor(arrivalPosition = 0) {
    this.triggers = document.getElementsByClassName('js-scroll-triggers') as HTMLCollectionOf<HTMLInputElement>;
    this.arrivalPosition = arrivalPosition;
    // IE と Safariでスムーススクロールを有効にするpolufill
    smoothscroll.polyfill();
  }

  onClick(): void {
    for (let i = 0; i < this.triggers.length; i++) {
      const trigger = this.triggers[i];
      trigger.addEventListener('click', () => {
        const targetName = trigger.dataset.scrolltrigger;
        const targetEl = document.querySelector(`[data-scrolltarget=${targetName}]`);
        const top = ScrollTo.getElementTop(targetEl) + this.arrivalPosition;
        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      });
    }
  }

  static getElementTop(el: Element): number {
    const rect = el.getBoundingClientRect();
    const top = rect.top + window.pageYOffset;
    return top;
  }
}
